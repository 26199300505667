import Cookies from "universal-cookie";
const axios = require("axios");

let source;
let cancelToken;

const generateTokens = () => {
  source = axios.CancelToken.source();
  cancelToken = source.token;
}

generateTokens();

export const cancelRequest = () => {
  source.cancel();
  generateTokens();
}

export const getFirstPublicSubset = (scopes) => {
  const publicSubset = scopes.filter((subset) => subset.public);
  return publicSubset[0];
};

export const cookieName = "userData";
export const baseApi = process.env.REACT_APP_BASE_API;

export const setLogin = async (username, password) => {
  const response = await axios.post(`${baseApi}/jwt-auth/v1/token`, {
    username,
    password,
  });
  const cookies = new Cookies();
  cookies.set(cookieName, response?.data?.token, {
    path: "/",
    maxAge: 60 * 60 * 24,
    secure: true,
  });
  return response?.data?.token;
};

export const getScopes = async (token) => {
  const response = await axios.get(
    `${baseApi}/ada-api/v1/stats/scopes`,
    {
      cancelToken,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response?.data;
};

export const getDashboardStats = async (token, scope, target) => {
  const params = { scope };
  if(target) params.target = target;
  const query = new URLSearchParams(params);
  const response = await axios.get(
    `${baseApi}/ada-api/v1/stats/dashboard?${query.toString()}`,
    {
      cancelToken,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response?.data;
};

export const getDashboardDetails = async (token, scope, target, module) => {
  const params = { scope, module };
  if(target) params.target = target;
  const query = new URLSearchParams(params);
  const response = await axios.get(
    `${baseApi}/ada-api/v1/stats/dashboard/details?${query.toString()}`,
    {
      cancelToken,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response?.data;
};