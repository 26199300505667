import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  loading: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingText: {
    marginTop: theme.spacing(3),
  },
}));
