import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import {
  cancelRequest, cookieName,
  getScopes,
  setLogin
} from "../helpers/userApi";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

const getInitialState = () => {
  const cookies = new Cookies();
  const token = cookies.get(cookieName);
  return {
    token,
    isAuthenticated: !!token,
    scopes: [],
  }
};

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, token: action.token };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, token: null };
    case "USER_LOADED":
      return { ...state, scopes: action.scopes, user: action.user };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const signOut = (dispatch, history) => {
  const cookies = new Cookies();
  cookies.remove(cookieName);
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  if(history) history.push("/connexion");
};

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, getInitialState());

  useEffect(() => {
    const loadScopes = async () => {
      try {
        const data = await getScopes(state.token);
        dispatch({ type: "USER_LOADED", ...data });
      } catch (err) {
        signOut(dispatch);
      }
    }
    if (state.token) loadScopes();
    return () => cancelRequest();
  }, [state.token]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

const loginUser = async (
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
) => {
  setError(false);
  setIsLoading(true);

  try {
    const token = await setLogin(login, password);
    setError(null);
    setIsLoading(false);
    dispatch({ type: "LOGIN_SUCCESS", token });
    history.push("/");
  } catch (err) {
    const code = err?.response?.data?.code;
    let message = "Une erreur s'est produite";
    switch (code) {
      case "[jwt_auth] incorrect_password":
      case "[jwt_auth] invalid_username":
        message =
          "Vos identifiants ne correspondent pas à notre base de données";
        break;
      default:
        break;
    }
    setError(message);
    setIsLoading(false);
  }
};

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################
