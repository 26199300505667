import React, { lazy, Suspense } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
// context
import { useUserState } from "../context/UserContext";
import Loading from "./Loading";
// pages
const Error = lazy(() => import("../pages/error"));
const Login = lazy(() => import("../pages/login"));
// components
const Layout = lazy(() => import("./Layout"));

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <HashRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <PublicRoute path="/connexion" component={Login} />
          <PrivateRoute path="/" component={Layout} />
          <Route component={Error} />
        </Switch>
      </Suspense>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/connexion",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
