import { CircularProgress } from "@material-ui/core";
import React from "react";
// components
import { Typography } from "../Wrappers";
// styles
import useStyles from "./styles";


export default function Loading({loading}) {
  var classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress size={75} />
      <Typography
        size="md"
        color="text"
        colorBrightness="secondary"
        className={classes.loadingText}
      >
        Chargement...
      </Typography>
    </div>
  );
}
